import React, { useState, useEffect } from 'react'
import Cookies from 'universal-cookie';
import axios from 'axios';
import { toast } from 'react-toastify'

const cookies = new Cookies();
toast.configure()

export default function Addmember(props) {
  const [username, setusername] = useState('');
  const [usergmail, setusergmail] = useState('');
  const [level, setlevel] = useState(props.AgentLevel);
  const [usernameAvail, setusernameAvail] = useState(false);
  const [pass, setpass] = useState('');
  const [confirmpass, setconfirmpass] = useState('');
  const [firstname, setfirstname] = useState('');
  const [My_Share, setMy_Share] = useState('0');
  const [Com_Share, setCom_Share] = useState('0');
  const [Match_Comm, setMatch_Comm] = useState('3');
  const [Sess_comm, setSess_comm] = useState('3');
  const [lastname, setlastname] = useState(' ');
  const [comm, setcomm] = useState(2);
  const [webtype, setwebtype] = useState('');
  const [Myshare, setMyshare] = useState('0');
  const [Comshare, setComshare] = useState('0');
  const [Matcom, setMatcom] = useState('0');
  const [Sesscom, setSesscom] = useState('0');



  useEffect(() => {

    var ssid = cookies.get('sid');
    if (!ssid) return;
    axios.post('https://flagapi.in/agentapi/NewmyComm', {
      sid: ssid

    })
      .then(result => {

        if (result.status === 200) {
          setcomm(result.data);
        }
      }
      ).catch(e => {
        //setIsError(true);
      });
    axios.post('https://flagapi.in/agentapi/myshareagentinfo', {
      sid: ssid,
      User: props.user,
      levelno: props.userLevel
    }).then(result => {
      if (result.status === 200) {
        console.log("ompk", result.data)
        setMyshare(result.data[0].share);
        setComshare(result.data[0].myShare);
        setMatcom(result.data[0].matchComm);
        setSesscom(result.data[0].sessionComm);

      }
    }

    ).catch(e => {
      //setIsError(true);
    });
  }, []);
  var strongRegex = /^([A-Za-z0-9])+$/;
  var test = username.match(strongRegex);

  const validateUser = () => {

    if (username === '' || username === ' ') {
      document.getElementById("userNameErrorText").style.color = "#024168";
      document.getElementById("userNameErrorText").innerHTML = "Username can not be blank!";
      return;
    }
    else if (!test) {
      document.getElementById("userNameErrorText").style.color = "#024168";
      document.getElementById("userNameErrorText").innerHTML = "Username is not valid!";
      return;
    }
    else if (username.length <= 6) {
      document.getElementById("userNameErrorText").innerHTML = "username must be atleast 6 char long!";
      return;
    }
    else {
      var ssid = cookies.get('sid');
      if (!ssid) return;
      axios.post('https://flagapi.in/agentapi/NewvalidateAgent', {
        sid: ssid,
        agentId: username

      })
        .then(result => {

          if (result.status === 200) {
            setusernameAvail(false);
            document.getElementById("userNameErrorText").style.color = "#024168";
            document.getElementById("userNameErrorText").innerHTML = "Username not available!";

          }
          else if (result.status === 206) {
            setusernameAvail(true);
            document.getElementById("userNameErrorText").style.color = "green";
            document.getElementById("userNameErrorText").innerHTML = "Username available!";

          }


        }

        ).catch(e => {
          //setIsError(true);
        });
    }
  }

  const validatePass = () => {

    if (pass.length < 4) {

      document.getElementById("passwordErrorText").innerHTML = "Password must be atleast 4 char long!";
      return;
    }
    else {
      document.getElementById("passwordErrorText").innerHTML = "";
    }

  }





  const confirmPassword = () => {

    if (pass !== confirmpass) {

      document.getElementById("repeatPasswordErrorText").innerHTML = "Password do not match!";
      return;
    }
    else {
      document.getElementById("repeatPasswordErrorText").innerHTML = "";
    }

  }

  const validateFirst = () => {
    if (firstname === '' || firstname === ' ') {
      document.getElementById("firstErrorText").innerHTML = "firstname can not be blank!";
    }
    else {
      document.getElementById("firstErrorText").innerHTML = "";
    }
  }

  const validateMatch = () => {
    if( Match_Comm<0)
    {
      document.getElementById("match_commision_validation").style.color = "#024168";
      document.getElementById("match_commision_validation").innerHTML = "Match Commsion must be greater or eqaul to Zero";
      setMatch_Comm(Match_Comm);
    }
 
  }
  // if( Sess_comm>=3 || Sess_comm<=0)
 const validateSession = () => {
    if( Sess_comm<0)
    {
      document.getElementById("session_commision_validation").style.color = "#024168";
      document.getElementById("session_commision_validation").innerHTML = "Match Commsion must be greater or eqaul to Zero";
      setSess_comm(Sess_comm);
    }
 
  }


  const NewaddAgent = () => {
    // if (usergmail === '' || usergmail === ' ') {
    //   document.getElementById("userGmailErrorText").innerHTML = "Must Enter Gmail ID";
    //   return;
    // }

    if (username === '' || username === ' ') {
      validateUser();


    }
    if (username === '' || username === ' ' || pass !== confirmpass || pass.length < 4 || usernameAvail === false || firstname === '' || firstname === ' ') {

      validatePass();
      confirmPassword();
      validateFirst();

      return;
    }

    var ssid = cookies.get('sid');
    if (!ssid) return;
    setMy_Share(document.getElementById("MyShare").value);
    setCom_Share(document.getElementById("Comm_share").value);

    axios.post('https://flagapi.in/agentapi/NewaddAgent', {
      sid: ssid,
      level: level,
      username: username,
      agentpass: pass,
      firstname: firstname,
      lastname: lastname,
      myShare: My_Share,
      share: Com_Share,
      matchComm: Match_Comm,
      sessionComm: Sess_comm




    })
      .then(result => {

        if (result.status === 200) {
          toast.success('Agent created successfully!', { position: toast.POSITION.TOP_CENTER })

          props.HandlePopup(4, false);
          props.changeUpdatedStatus();



        }

        else {
          toast.warn('Something went wrong!', { position: toast.POSITION.TOP_CENTER })
        }


      }

      ).catch(e => {
        //setIsError(true);
      });
  }

  const setmysharefun = (myshare) => {
    var mysharee = document.getElementById("MyShare").value = myshare;
    var comm = Myshare - mysharee;
    setMy_Share(comm);
    setCom_Share(mysharee);
    document.getElementById("Comm_share").value = comm;
  }

  var Level;
  if (level == 2) {
    Level = "Admin (SSS)"
  }




  return (
    <React.Fragment>
      <div id="createModal" class="pop_bg" style={{ top: '0', display: 'block' }}>
        <div class="pop_box ">
          <a class="close_pop" onClick={() => { props.HandlePopup(4, false) }}>close_pop</a>

          <h3>Add Agent
          </h3>
          <ul class="half_box add-member-box">
            <li class="add_account_box">
              <dl class="border_b">              
                <dt>Username</dt>
                <dd>
                  <input onChange={(e) => { setusername(e.target.value); }} onBlur={() => { validateUser(); }} id="userName" type="text" value={username} placeholder="Enter" maxlength="16" />
                  <span class="must">＊</span>
                  <span id="userNameErrorText" class="error-text" ></span>
                </dd>
                <dt>Password</dt>
                <dd>
                  <input onChange={(e) => { setpass(e.target.value); }} onBlur={() => { validatePass(); }} value={pass} id="userPassword" type="password" placeholder="Enter" />
                  <span class="must">＊</span>
                  <span id="passwordErrorText" class="error-text"></span>
                </dd>
                <dt>Confirm Password</dt>
                <dd>
                  <input onChange={(e) => { setconfirmpass(e.target.value); }} onBlur={() => { confirmPassword(); }} value={confirmpass} id="repeatPassword" type="password" placeholder="Enter" />
                  <span class="must">＊</span>
                  <span id="repeatPasswordErrorText" class="error-text"></span>
                </dd>
              </dl>
              <dl class="">
                <dt>First Name</dt>
                <dd>
                  <input onChange={(e) => { setfirstname(e.target.value); }} onBlur={() => { validateFirst(); }} value={firstname} id="firstName" type="text" placeholder="Enter" maxlength="16" />
                  <span class="must">＊</span>
                  <span id="firstErrorText" class="error-text"></span>
                </dd>
                <dt>Last Name</dt>
                <dd>
                  <input onChange={(e) => { setlastname(e.target.value); }} value={lastname} id="lastName" type="text" placeholder="Enter" maxlength="16" />
                </dd>
                <dt>My Match Share</dt>
                <dd>
                  <input onChange={(e) => { setmysharefun(e.target.value); }}  id="MyShare" type="text" placeholder="Enter" maxlength="16" />
                  <span class="must">＊</span>
                  <span id="match_share_validation" class="error-text"></span>
                </dd>
                <dt>Company Share</dt>
                <dd>
                  {/* <input onChange={(e) => { setCom_Share(e.target.value); }} value={Com_Share} id="Comm_share" type="text" placeholder="Enter" maxlength="16" /> */}
                  <input id="Comm_share" type="text" placeholder="Enter" maxlength="16" />
                  

                </dd>
                <dt>Match Commission</dt>
                <dd>
                  <input onChange={(e) => { setMatch_Comm(e.target.value); }} onBlur={() => { validateMatch(); }} value={Match_Comm} id="MAtchCoomm" type="text" placeholder="Enter" maxlength="16" />
                  <span class="must">＊</span>
                  <span id="match_commision_validation" class="error-text"></span>
                </dd>
                <dt>Session Commission</dt>
                <dd>
                  <input onChange={(e) => { setSess_comm(e.target.value); }} onBlur={() => { validateSession(); }} value={Sess_comm} id="sessCoomm" type="text" placeholder="Enter" maxlength="16" />
                  <span class="must">＊</span>
                  <span id="session_commision_validation" class="error-text"></span>
                </dd>



                <dt>Time Zone</dt>
                <dd>
                  <div value="IST">IST(Bangalore / Bombay)
                    <span class="must">＊</span>
                  </div>
            
                </dd>
              </dl>
            </li>
        
          </ul>
          <div class="btn_box">
            <a id="createBtn" onClick={() => { NewaddAgent(); }} class="btn-send">Create</a>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
