import React, { useState, useEffect } from 'react'
import Transparent from './images/transparent.gif'
import axios from 'axios';
import Cookies from 'universal-cookie';
import moment from 'moment'
import MarketView from './MarketView'





const io = require('socket.io-client');
const cookies = new Cookies();

const ranNum = Math.floor(Math.random() * 4) + 1;
var ENDPOINT;
if (ranNum == 1) {
    ENDPOINT = "https://flagapi.in:2053";
}
else if (ranNum == 2) {
    ENDPOINT = "https://flagapi.in:2083";
}
else if (ranNum == 3) {
    ENDPOINT = "https://flagapi.in:2087";
}
else {
    ENDPOINT = "https://flagapi.in:2096";
}

var socket;



var c1 = 0, c2 = 0, c3 = 0, c4 = 0, c5 = 0, c6 = 0, c7 = 0, c8 = 0, c9 = 0, c10 = 0, c11 = 0, c12 = 0, c13 = 0, c14 = 0, c15 = 0, c16 = 0, c17 = 0, c18 = 0;
var s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15, s16, s17, s18, s19, s20, s21, s22, s23, s24, s25, s26, s27, s28, s29, s30, s31, s32, s33, s34, s35, s36;


export default function TennisMatchSock(props) {
    const [runner1BackRate1, setrunner1BackRate1] = useState(' ');
    const [runner1BackRate2, setrunner1BackRate2] = useState(' ');
    const [bookData, setbookData] = useState({})
    const [marketData, setmarketData] = useState({})
    const [bookData2, setbookData2] = useState({})
    const [runner1BackRate3, setrunner1BackRate3] = useState(' ');
    const [runner1BackSize1, setrunner1BackSize1] = useState(' ');
    const [runner1BackSize2, setrunner1BackSize2] = useState(' ');
    const [runner1BackSize3, setrunner1BackSize3] = useState(' ');
    const [runner1LayRate1, setrunner1LayRate1] = useState(' ');
    const [runner1LayRate2, setrunner1LayRate2] = useState(' ');
    const [runner1LayRate3, setrunner1LayRate3] = useState(' ');
    const [runner1LaySize1, setrunner1LaySize1] = useState(' ');
    const [runner1LaySize2, setrunner1LaySize2] = useState(' ');
    const [runner1LaySize3, setrunner1LaySize3] = useState(' ');
    const [showDownline, setshowDownline] = useState(false);
    const [runner2BackRate1, setrunner2BackRate1] = useState(' ');
    const [runner2BackRate2, setrunner2BackRate2] = useState(' ');
    const [runner2BackRate3, setrunner2BackRate3] = useState(' ');
    const [runner2BackSize1, setrunner2BackSize1] = useState(' ');
    const [runner2BackSize2, setrunner2BackSize2] = useState(' ');
    const [runner2BackSize3, setrunner2BackSize3] = useState(' ');
    const [runner2LayRate1, setrunner2LayRate1] = useState(' ');
    const [runner2LayRate2, setrunner2LayRate2] = useState(' ');
    const [runner2LayRate3, setrunner2LayRate3] = useState(' ');
    const [runner2LaySize1, setrunner2LaySize1] = useState(' ');
    const [runner2LaySize2, setrunner2LaySize2] = useState(' ');
    const [runner2LaySize3, setrunner2LaySize3] = useState(' ');

    const [tieBackRate1, settieBackRate1] = useState(' ');
    const [tieBackRate2, settieBackRate2] = useState(' ');
    const [tieBackRate3, settieBackRate3] = useState(' ');
    const [tieBackSize1, settieBackSize1] = useState(' ');
    const [tieBackSize2, settieBackSize2] = useState(' ');
    const [tieBackSize3, settieBackSize3] = useState(' ');
    const [tieLayRate1, settieLayRate1] = useState(' ');
    const [tieLayRate2, settieLayRate2] = useState(' ');
    const [tieLayRate3, settieLayRate3] = useState(' ');
    const [tieLaySize1, settieLaySize1] = useState(' ');
    const [tieLaySize2, settieLaySize2] = useState(' ');
    const [tieLaySize3, settieLaySize3] = useState(' ');

    const [fancyData, setfancyData] = useState([])
    const [marketBetStatus, setmarketBetStatus] = useState(0);
    const [popup, setpopup] = useState(null);

    const [refresh, setrefresh] = useState(true);
    const [marketpnl1, setmarketpnl1] = useState(0);
    const [marketpnl2, setmarketpnl2] = useState(0);
    const [marketpnl3, setmarketpnl3] = useState(0);
    const [bookpnl1, setbookpnl1] = useState(0);
    const [bookpnl2, setbookpnl2] = useState(0);
    const [bookpnl3, setbookpnl3] = useState(0);
    const [fancySelection, setfancySelection] = useState(null);
    const [fancyName, setfancyName] = useState('');
    useEffect(() => {
        socket = io(ENDPOINT);
        // console.log('socket connected');
        socket.on('connect', function (data) {
            console.log(props.eventId);
            socket.emit('room1', props.eventId);
        });

        return () => {
            socket.close();
            // console.log('socket disconnected');
            s1 = -1; s2 = -1; s3 = -1; s4 = -1; s5 = -1; s6 = -1; s7 = -1; s8 = -1; s9 = -1; s10 = -1; s11 = -1; s12 = -1; s13 = -1; s14 = -1; s15 = -1; s16 = -1; s17 = -1; s18 = -1; s19 = -1; s20 = -1; s21 = -1; s22 = -1; s23 = -1; s24 = -1;
        }
    }, [props.eventId])







    useEffect(() => {
        getProfitLoss();

        const intervalId = setInterval(() => {
            getProfitLoss();
        }, 5000);


        return () => clearInterval(intervalId);

    }, [marketData, bookData2, props.eventId])



    useEffect(() => {


        if (!props.eventId) return;

        axios.post('https://flagapi.in/clientapi/getFullMarket', {

            eventId: props.eventId,

        })
            .then(result => {
                // console.log(result);

                if (result.data[0]) {
                    let obj = result.data[0];
                    setrunner1BackRate1(obj.runner1BackRate1);
                    setrunner1BackRate2(obj.runner1BackRate2);
                    setrunner1BackRate3(obj.runner1BackRate3);
                    setrunner1LayRate1(obj.runner1LayRate1);
                    setrunner1LayRate2(obj.runner1LayRate2);
                    setrunner1LayRate3(obj.runner1LayRate3);
                    setrunner2BackRate1(obj.runner2BackRate1);
                    setrunner2BackRate2(obj.runner2BackRate2);
                    setrunner2BackRate3(obj.runner2BackRate3);
                    setrunner2LayRate1(obj.runner2LayRate1);
                    setrunner2LayRate2(obj.runner2LayRate2);
                    setrunner2LayRate3(obj.runner2LayRate3);

                    if (obj.runnerId3) {
                        settieBackRate1(obj.tieBackRate1);
                        settieBackRate2(obj.tieBackRate2);
                        settieBackRate3(obj.tieBackRate3);
                        settieLayRate1(obj.tieLayRate1);
                        settieLayRate2(obj.tieLayRate2);
                        settieLayRate3(obj.tieLayRate3);

                    }


                    setmarketData(obj);
                }

            })
            .catch(e => {

            });


        axios.post('https://flagapi.in/clientapi/getBookMakerMarket', {

            eventId: props.eventId,

        })
            .then(result => {


                if (result.data[0]) {
                    let obj = result.data[0];
                    setbookData2(obj);
                }



            })
            .catch(e => {

            });
    }, []);



    const getProfitLoss = () => {
        var ssid = cookies.get('sid');

        if (!ssid) return;
        if (!props.eventId) return;

        if (marketData.marketId) {
            axios.post('https://flagapi.in/agentapi/newagentMarketExposure', {
                sid: ssid,
                eventId: props.eventId,
                marketId: marketData.marketId

            })
                .then(result => {

                    if (refresh) { setrefresh(false); }
                    if (result.status === 200) {

                        setmarketpnl1(result.data.teamA_total);
                        setmarketpnl2(result.data.teamB_total);
                        setmarketpnl3(result.data.draw_total);
                        console.log(result.data);

                    }

                })
                .catch(e => {

                });
        }

        if (bookData2.marketId) {

            axios.post('https://flagapi.in/agentapi/newagentMarketExposure', {
                sid: ssid,
                eventId: props.eventId,
                marketId: bookData2.marketId

            })
                .then(result => {

                    if (refresh) { setrefresh(false); }
                    if (result.status === 200) {

                        setbookpnl1(result.data.teamA_total);
                        setbookpnl2(result.data.teamB_total);
                        setbookpnl3(result.data.draw_total);


                    }

                })
                .catch(e => {

                });

        }

    }





    useEffect(() => {

        socket.on(props.eventId, (val) => {
            //    console.log(val);
            var value = JSON.parse(val);

            if (value.messageType === 'betfair_match_rate') {

                setmarketBetStatus(value.status);

                // if(value.status == '0' && liquidityStatus == 0) {
                //     setliquidityStatus(1);

                // }
                if (s1 !== value.runner1BackRate1) {
                    setrunner1BackRate1(value.runner1BackRate1);
                    s1 = value.runner1BackRate1;
                    c1 = 1;
                    setTimeout(() => { c1 = 0; }, 700);
                }
                if (s2 !== value.runner1BackRate2) {
                    setrunner1BackRate2(value.runner1BackRate2);
                    s2 = value.runner1BackRate2;
                    c2 = 1;
                    setTimeout(() => { c2 = 0; }, 700);
                }
                if (s3 !== value.runner1BackRate3) {
                    setrunner1BackRate3(value.runner1BackRate3);
                    c3 = 1;
                    s3 = value.runner1BackRate3;
                    setTimeout(() => { c3 = 0; }, 700);
                }
                if (s4 !== value.runner1BackSize1) {
                    setrunner1BackSize1(value.runner1BackSize1);
                    s4 = value.runner1BackSize1;
                }
                if (s5 !== value.runner1BackSize2) {
                    setrunner1BackSize2(value.runner1BackSize2);
                    s5 = value.runner1BackSize2;
                }
                if (s6 !== value.runner1BackSize3) {
                    setrunner1BackSize3(value.runner1BackSize3);
                    s6 = value.runner1BackSize3;
                }
                if (s7 !== value.runner1LayRate1) {
                    setrunner1LayRate1(value.runner1LayRate1);
                    s7 = value.runner1LayRate1;
                    c4 = 1;
                    setTimeout(() => { c4 = 0; }, 700);
                }
                if (s8 !== value.runner1LayRate2) {
                    setrunner1LayRate2(value.runner1LayRate2);
                    s8 = value.runner1LayRate2;
                    c5 = 1;
                    setTimeout(() => { c5 = 0; }, 700);
                }
                if (s9 !== value.runner1LayRate3) {
                    setrunner1LayRate3(value.runner1LayRate3);
                    s9 = value.runner1LayRate3;
                    c6 = 1;
                    setTimeout(() => { c6 = 0; }, 700);
                }
                if (s10 !== value.runner1LaySize1) {
                    setrunner1LaySize1(value.runner1LaySize1);
                    s10 = value.runner1LaySize1;
                }
                if (s11 !== value.runner1LaySize2) {
                    setrunner1LaySize2(value.runner1LaySize2);
                    s11 = value.runner1LaySize2;
                }
                if (s12 !== value.runner1LaySize3) {
                    setrunner1LaySize3(value.runner1LaySize3);
                    s12 = value.runner1LaySize3;
                }
                if (s13 !== value.runner2BackRate1) {
                    setrunner2BackRate1(value.runner2BackRate1);
                    s13 = value.runner2BackRate1;
                    c7 = 1;
                    setTimeout(() => { c7 = 0; }, 700);
                }
                if (s14 !== value.runner2BackRate2) {
                    setrunner2BackRate2(value.runner2BackRate2);
                    s14 = value.runner2BackRate2;
                    c8 = 1;
                    setTimeout(() => { c8 = 0; }, 700);
                }
                if (s15 !== value.runner2BackRate3) {
                    setrunner2BackRate3(value.runner2BackRate3);
                    s15 = value.runner2BackRate3;
                    c9 = 1;
                    setTimeout(() => { c9 = 0; }, 700);
                }
                if (s16 !== value.runner2BackSize1) {
                    setrunner2BackSize1(value.runner2BackSize1);
                    s16 = value.runner2BackSize1;
                }
                if (s17 !== value.runner2BackSize2) {
                    setrunner2BackSize2(value.runner2BackSize2);
                    s17 = value.runner2BackSize2;
                }
                if (s18 !== value.runner2BackSize3) {
                    setrunner2BackSize3(value.runner2BackSize3);
                    s18 = value.runner2BackSize3;
                }
                if (s19 !== value.runner2LayRate1) {
                    setrunner2LayRate1(value.runner2LayRate1);
                    s19 = value.runner2LayRate1;
                    c10 = 1;
                    setTimeout(() => { c10 = 0; }, 700);
                }
                if (s20 !== value.runner2LayRate2) {
                    setrunner2LayRate2(value.runner2LayRate2);
                    s20 = value.runner2LayRate2;
                    c11 = 1;
                    setTimeout(() => { c11 = 0; }, 700);
                }
                if (s21 !== value.runner2LayRate3) {
                    setrunner2LayRate3(value.runner2LayRate3);
                    s21 = value.runner2LayRate3;
                    c12 = 1;
                    setTimeout(() => { c12 = 0; }, 700);
                }
                if (s22 !== value.runner2LaySize1) {
                    setrunner2LaySize1(value.runner2LaySize1);
                    s22 = value.runner2LaySize1;
                }
                if (s23 !== value.runner2LaySize2) {
                    setrunner2LaySize2(value.runner2LaySize2);
                    s23 = value.runner2LaySize2;
                }
                if (s24 !== value.runner2LaySize3) {
                    setrunner2LaySize3(value.runner2LaySize3);
                    s24 = value.runner2LaySize3;
                }


                if (s25 !== value.tieBackRate1) {
                    settieBackRate1(value.tieBackRate1);
                    s25 = value.tieBackRate1;
                    c13 = 1;
                    setTimeout(() => { c13 = 0; }, 700);
                }
                if (s26 !== value.tieBackRate2) {
                    settieBackRate2(value.tieBackRate2);
                    s26 = value.tieBackRate2;
                    c14 = 1;
                    setTimeout(() => { c14 = 0; }, 700);
                }
                if (s27 !== value.tieBackRate3) {
                    settieBackRate3(value.tieBackRate3);
                    s27 = value.tieBackRate3;
                    c15 = 1;
                    setTimeout(() => { c15 = 0; }, 700);
                }
                if (s28 !== value.tieBackSize1) {
                    settieBackSize1(value.tieBackSize1);
                    s28 = value.tieBackSize1;
                }
                if (s29 !== value.tieBackSize2) {
                    settieBackSize2(value.tieBackSize2);
                    s29 = value.tieBackSize2;
                }
                if (s30 !== value.tieBackSize3) {
                    settieBackSize3(value.tieBackSize3);
                    s30 = value.tieBackSize3;
                }
                if (s31 !== value.tieLayRate1) {
                    settieLayRate1(value.tieLayRate1);
                    s31 = value.tieLayRate1;
                    c16 = 1;
                    setTimeout(() => { c16 = 0; }, 700);
                }
                if (s32 !== value.tieLayRate2) {
                    settieLayRate2(value.tieLayRate2);
                    s32 = value.tieLayRate2;
                    c17 = 1;
                    setTimeout(() => { c17 = 0; }, 700);
                }
                if (s33 !== value.tieLayRate3) {
                    settieLayRate3(value.tieLayRate3);
                    s33 = value.tieLayRate3;
                    c18 = 1;
                    setTimeout(() => { c18 = 0; }, 700);
                }
                if (s34 !== value.tieLaySize1) {
                    settieLaySize1(value.tieLaySize1);
                    s34 = value.tieLaySize1;
                }
                if (s35 !== value.tieLaySize2) {
                    settieLaySize2(value.tieLaySize2);
                    s35 = value.tieLaySize2;
                }
                if (s36 !== value.tieLaySize3) {
                    settieLaySize3(value.tieLaySize3);
                    s36 = value.tieLaySize3;
                }

            }


            if (value[0] && value[0].messageType === 'session_rate') {
                let temp = value;
                temp.sort(function (a, b) {

                    return moment(a.selectionId) - moment(b.selectionId);
                });

                setfancyData(temp);
                //    console.log(temp);

            }
            //    console.log(value.messageType,"bookmaker");
            if (value.messageType === 'bookmaker_match_rate') {
                //console.log('book1',value);
                let temp = { ...bookData };

                temp.gameStatus = value.status;
                temp.runner1Status = value.runner1Status;
                temp.runner2Status = value.runner2Status;
                temp.runner3Status = value.runner3Status;
                temp.runner1BackRate1 = value.runner1BackRate1;
                temp.runner1BackRate2 = value.runner1BackRate2;
                temp.runner1BackRate3 = value.runner1BackRate3;
                temp.runner1LayRate1 = value.runner1LayRate1;
                temp.runner1LayRate2 = value.runner1LayRate2;
                temp.runner1LayRate3 = value.runner1LayRate3;

                temp.runner2BackRate1 = value.runner2BackRate1;
                temp.runner2BackRate2 = value.runner2BackRate2;
                temp.runner2BackRate3 = value.runner2BackRate3;
                temp.runner2LayRate1 = value.runner2LayRate1;
                temp.runner2LayRate2 = value.runner2LayRate2;
                temp.runner2LayRate3 = value.runner2LayRate3;

                temp.tieBackRate1 = value.tieBackRate1;
                temp.tieBackRate2 = value.tieBackRate2;
                temp.tieBackRate3 = value.tieBackRate3;
                temp.tieLayRate1 = value.tieLayRate1;
                temp.tieLayRate2 = value.tieLayRate2;
                temp.tieLayRate3 = value.tieLayRate3;
                console.log('bookmaker', bookData.gameStatus, 'runner1', bookData.runner1Status, 'runner2', bookData.runner2Status);

                setbookData(temp);

            }
        })
        return () => {
            socket.removeAllListeners(props.eventId);
            setrunner1BackRate1(' '); setrunner1BackRate2(' '); setrunner1BackRate3(' '); setrunner2BackRate1(' '); setrunner2BackRate2(' '); setrunner2BackRate3(' ');
            setrunner1LayRate1(' '); setrunner1LayRate2(' '); setrunner1LayRate3(' '); setrunner2LayRate1(' '); setrunner2LayRate2(' '); setrunner2LayRate3(' ');
            setrunner1BackSize1(' '); setrunner1BackSize2(' '); setrunner1BackSize3(' '); setrunner2BackSize1(' '); setrunner2BackSize2(' '); setrunner2BackSize3(' ');
            setrunner1LaySize1(' '); setrunner1LaySize2(' '); setrunner1LaySize3(' '); setrunner2LaySize1(' '); setrunner2LaySize2(' '); setrunner2LaySize3(' ');
            settieBackRate1(' '); settieBackRate2(' '); settieBackRate3(' '); settieLayRate1(' '); settieLayRate2(' '); settieLayRate3(' '); settieBackSize1(' ');
            settieBackSize2(' '); settieBackSize3(' '); settieLaySize1(' '); settieLaySize2(' '); settieLaySize3(' ');


            setfancyData([]);
        }



    }, [])
    return (
        <div>
            {/* {marketData.matchStatus == 1 &&  */}
            <div className="iframert" style={{ height: '200px' }}>
                <div className="for-agent">
                    <div className="head-bets-agent">
                        <span className="game-low_liq" id="lowLiquidity" style={{ display: 'none' }}>Low Liquidity</span>
                        <dl className="game-matched">
                            <dt>Match Odds</dt>

                        </dl>
                        <dl className="game-matched">
                            <dt>Matched</dt>
                            {/* <dd id="totalMatched">USD336,281,366</dd> */}
                        </dl>
                        <ul className="game-info">
                            <li className="green" id="inPlay" style={{}}>
                                <img className="icon-irun" src={Transparent} />In-Play</li>
                        </ul>
                    </div>
                    <div className="bets-wrap">
                        <span className="refer_only"><span id="selectionCount">3</span> Selections</span>


                        <table className="bets" id="marketOdds">
                            <tbody>
                                <tr className="bet-all">
                                    <td />
                                    <td className="refer-bet" colSpan={2} id="backPercent">100.7%</td>
                                    <td>

                                        <a className="back-all">
                                            <span>Back all</span></a>
                                    </td>
                                    <td>

                                        <a className="lay-all">
                                            <span>Lay all</span></a>
                                    </td>
                                    <td className="refer-book" colSpan={2} id="layPercent">99.8%</td>
                                </tr>
                            </tbody>
                            <tbody id="content">
                                <tr id="31135408_414464">
                                    <th className id="selection">
                                        <p>
                                            <a id="marketDepth">
                                                <img className="icon-predict" src={Transparent} /></a>{marketData.runnerName1} <span class="red" style={{ float: 'right' }}>{marketpnl1 >= 0 ? parseFloat(marketpnl1).toFixed(2) : '(' + parseFloat(Math.abs(marketpnl1)).toFixed(2) + ')'}</span></p>
                                    </th>
                                    {/* <td> {marketpnl1 >= 0 ? parseFloat(marketpnl1).toFixed(2) : '('+ parseFloat(Math.abs(marketpnl1)).toFixed(2) +')'}</td> */}
                                    <td className="back-3" id="back_3">
                                        <a >{runner1BackRate3}<span>{runner1BackSize3}</span></a></td>
                                    <td className="back-2" id="back_2">
                                        <a >{runner1BackRate2}<span>{runner1BackSize2}</span></a></td>
                                    <td className={`back-1 ${(c1 === 1) ? "spark" : "null"}`} id="back_1">
                                        <a >{runner1BackRate1}<span>{runner1BackSize1}</span></a></td>
                                    <td className={`lay-1 ${(c2 === 1) ? "spark" : "null"}`} id="lay_1">
                                        <a >{runner1LayRate1}<span>{runner1LaySize1}</span></a></td>
                                    <td className="lay-2 spark" id="lay_2">
                                        <a >{runner1LayRate2}<span>{runner1LaySize2}</span></a></td>
                                    <td className="lay-3 spark" id="lay_3">
                                        <a >{runner1LayRate3}<span>{runner1LaySize3}</span></a></td>
                                </tr>
                                <tr id="31135408_60443">
                                    <th className id="selection">
                                        <p>
                                            <a id="marketDepth">
                                                <img className="icon-predict" src={Transparent} />
                                            </a>{marketData.runnerName2} <span className={`${marketpnl2 >= 0 ? "win" : "lose"}`} style={{ float: 'right' }}>{marketpnl2 >= 0 ? parseFloat(marketpnl2).toFixed(2) : '(' + parseFloat(Math.abs(marketpnl2)).toFixed(2) + ')'}</span></p>
                                    </th>
                                    {/* <td className="green">{marketpnl2 >= 0 ? parseFloat(marketpnl2).toFixed(2) : '('+ parseFloat(Math.abs(marketpnl2)).toFixed(2) +')'}</td> */}
                                    <td className="back-3" id="back_3">
                                        <a >{runner2BackRate3}<span>{runner2BackSize3}</span></a></td>
                                    <td className="back-2" id="back_2">
                                        <a >{runner2BackRate2}<span>{runner2BackSize2}</span></a></td>
                                    <td className={`back-1 ${(c3 === 1) ? "spark" : "null"}`} id="back_1">
                                        <a >{runner2BackRate1}<span>{runner2BackSize1}</span></a></td>
                                    <td className={`lay-1 ${(c4 === 1) ? "spark" : "null"}`} id="lay_1">
                                        <a >{runner2LayRate1}<span>{runner2LaySize1}</span></a></td>
                                    <td className="lay-2" id="lay_2">
                                        <a >{runner2LayRate2}<span>{runner2LaySize2}</span></a></td>
                                    <td className="lay-3" id="lay_3">
                                        <a >{runner2LayRate3}<span>{runner2LaySize3}</span></a></td>
                                </tr>

                                {marketData.runnerId3 && marketData.runnerId3 != ' ' && <React.Fragment>
                                    <tr id="31135408_349">
                                        <th className id="selection">
                                            <p>
                                                <a id="marketDepth">

                                                    <img className="icon-predict" src={Transparent} /></a>The Draw</p>
                                        </th>
                                        {/* <td>{marketpnl3 >= 0 ? parseFloat(marketpnl3).toFixed(2) : '('+ parseFloat(Math.abs(marketpnl3)).toFixed(2) +')'}</td> */}
                                        <td className="back-3 spark" id="back_3">
                                            <a >{tieBackRate3}<span>{tieBackSize3}</span></a></td>
                                        <td className="back-2 spark" id="back_2">
                                            <a >{tieBackRate2}<span>{tieBackSize2}</span></a></td>
                                        <td className={`back-1 ${(c5 === 1) ? "spark" : "null"}`} id="back_1">
                                            <a >{tieBackRate1}<span>{tieBackSize1}</span></a></td>
                                        <td className={`lay-1 ${(c6 === 1) ? "spark" : "null"}`} id="lay_1">
                                            <a >{tieLayRate1}<span>{tieLaySize1}</span></a></td>
                                        <td className="lay-2" id="lay_2">
                                            <a >{tieLayRate2}<span>{tieLaySize2}</span></a></td>
                                        <td className="lay-3 spark" id="lay_3">
                                            <a >{tieLayRate3}<span>{tieLaySize3}</span></a></td>
                                    </tr>
                                </React.Fragment>}
                            </tbody>
                        </table>
                    </div>
                </div>


            </div>
            {/* }  */}


        </div>
    )
}
